import React from "react";
import { Link } from "gatsby";
import { MailIcon } from "@heroicons/react/solid";
import { BsFacebook, BsInstagram, BsTelegram } from "react-icons/bs";
import Layout from "../components/layout";
import FormComponent from "../components/ui/FormComponent";
import { TitleComponent } from "../components/ui/TitleComponent";
import { BoxComponent } from "../components/ui/BoxComponent";
import MarginComponent from "../components/ui/MarginComponent";

export default function ContactPage() {
  return (
    <Layout
      title="Контакты"
      description="Звоните и оставляйте заявку на нашем сайте. Мы в социальных сетях."
    >
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Оставить заявку</TitleComponent>
        <FormComponent />
      </MarginComponent>
    </Layout>
  );
}
